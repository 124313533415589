import { ApolloClient, createHttpLink, from, InMemoryCache, makeVar } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { LocalStorageWrapper, persistCache, PersistentStorage } from 'apollo3-cache-persist';
import { PersistedData } from 'apollo3-cache-persist/lib/types';
import { PARENT_PATH } from '@app/components/router/RequireAuth';
import { useLocation } from 'react-router-dom';
import { FastBackwardFilled } from '@ant-design/icons';
//import { isLocal } from '../components/Exam/containers/GameFullScreen';

const isLocal = () => {
  return window.location.host.indexOf('localhost') !== -1;
};
// CachePersistor, LocalStorageWrapper
// https://github.com/apollographql/apollo-cache-persist/blob/master/examples/web/src/index.tsx

//import { setContext } from '@apollo/client/link/context';
//import jwtManager from '../services/jwtManager';
//import { refreshAccessToken } from './AuthProvider';

/*
const authLink = setContext((_, { headers }) => {
    const { getToken } = jwtManager();
    const access_token = getToken();
    return {
        headers: {
            ...headers,
            authorization: access_token ? `Bearer ${access_token}` : ""
        }
    };
});
*/

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      switch (extensions && extensions.code) {
        case 'UNAUTHENTICATED':
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              //                        authorization: refreshAccessToken(),
            },
          });
          return forward(operation);
        default:
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      }
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

// --- dark Mode ----
// ref : https://defineall.tistory.com/903

export const darkMode = makeVar(false);

export type UserQuery = {
  userData: {
    id: string;
    age: string;
    details: string;
    access_date_utc: string;
  };
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        darkMode: {
          read() {
            return darkMode();
          },
        },
      },
    },
  },
});

//persist store persisted the cache
export const isHost = (domain: string) => {
  return window.location.host === domain;
};
persistCache({
  cache: cache,
  storage: new LocalStorageWrapper(window.localStorage),
})
  .then((result) => {
    //console.log('storage is persisted')
    //console.log('result from persisted store ->>>> ' , result)
  })
  .catch((err) => console.log(err));


const httpLink = createHttpLink({
  //uri: true
  //  ? 'https://7dvfzrxjl8.execute-api.ap-northeast-2.amazonaws.com/dev/graphql'//
  uri: isHost('ica.mbisq.com')
    ? 
    //'https://r3.liflo.io'
    'https://db.mbisq.com'
    //'https://mrhxj3wlj3.execute-api.ap-northeast-2.amazonaws.com' // real
    //: 'https://db.mbisq.com/graphql'
    //: 'https://7dvfzrxjl8.execute-api.ap-northeast-2.amazonaws.com/graphql', 
    //: 'https://mrhxj3wlj3.execute-api.ap-northeast-2.amazonaws.com',
    //: 'https://ymd2.mbisq.com/',
    : 'http://localhost:3003/',
    //: 'https://r3.liflo.io', // dev
    //: 'https://db.mbisq.com', // dev
});

const authLink = setContext((_, { headers }) => {
  const userCategory = localStorage.getItem('userCategory');
  const token = PARENT_PATH.includes(window.location.pathname)
    ? localStorage.getItem('parentUserToken')
    : localStorage.getItem('userToken');

  // const token = userCategory === 'parent' ? localStorage.getItem('parentUserToken') : localStorage.getItem('userToken');
  // let token: any;
  // const path = window.location.pathname; // 현재 URL 경로 가져오기
  // if (PARENT_PATH.includes(path) && userCategory === 'parent') {
  //   token = localStorage.getItem('parentUserToken');
  // } else {
  //   token = localStorage.getItem('userToken');
  // }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const createApolloClient = new ApolloClient({
  cache: cache, //new InMemoryCache(),
  //uri: "https://mrhxj3wlj3.execute-api.ap-northeast-2.amazonaws.com/",
  link: from([errorLink, authLink, httpLink]),
});

export default createApolloClient;
/**
 * {
    typePolicies :{
       User: {  //typename from the backend
         fields : {  //fields we want to include
           loginId: { 
            //field we included or we can say that local field not coming from the server
             
            read(_ , {variables}){
              return localStorage.getItem('userId')
           }
         }      
        }
     },
     Task: {  //we can manupulate data before calling the query
        fields: { //here i converted the title property
          title:{  //of Task typename to UpperCase 
            read(title) {
              return title.toUpperCase()
            }
          }
        }
     },
     Query: {
       fields : {
         darkMode :{
           read() {
             return darkMode()
           }
         }
       }
     }
  }
}
 */
